<template>
  <apexchart
    type="line"
    :options="chartOptions"
    :series="[postDedupSeries, preDedupSeries, globalCompPercentageSeries, physicalUsedFactorSeries, logicalUsedFactorSeries]"
  />
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
import moment from '@/libs/moment'
import DataDomainApexService from '@/service/datadomain-apex.service'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    timeRange: {
      type: Object,
      default: () => ({
        startDate: moment().subtract(14, 'days').toDate(),
        endDate: new Date(),
      }),
    },
    timezone: {
      type: String,
      default: 'Etc/UTC',
    },
    mtreeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chartDataLoaded: false,
      isRendered: false,
      statistic: [],
    }
  },
  computed: {
    asupId() {
      return this.$route.params.id
    },
    startDate() {
      if (this.$route.query.start) {
        return this.$route.query.start
      }

      return this.timeRange.startDate
    },
    endDate() {
      if (this.$route.query.end) {
        return this.$route.query.end
      }

      return this.timeRange.endDate
    },
    oldestStatistic() {
      return this.statistic
        .concat()
        .sort((a, b) => ((Date.parse(a.startDate) > Date.parse((b.startDate)) ? -1 : 1)))[0]
    },
    chartOptions() {
      return {
        chart: {
          id: 'dailyCapacityPreAndPostDedupChart',
          type: 'line',
          height: 350,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        stroke: {
          show: true,
          curve: 'straight',
          lineCap: 'butt',
          width: 1.5,
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        title: {
          text: `${this.mtreeName} Statistic`,
          align: 'left',
        },
        yaxis: [
          {
            seriesName: 'Used Capacity',
            labels: {
              formatter(val) {
                return `${(val).toFixed(0)} GiB`
              },
            },
            title: {
              text: 'Used Capacity',
            },
            minWidth: 0,
          },
          {
            seriesName: 'Pre dedup',
            labels: {
              formatter(val) {
                return `${(val).toFixed(0)} GiB`
              },
            },
            title: {
              text: 'Capacity Pre dedup',
            },
            minWidth: 0,
          },
          {
            seriesName: 'Global comp.',
            labels: {
              formatter(val) {
                return `${(val).toFixed(0)}`
              },
            },
            title: {
              text: 'Percentage %',
            },
            minWidth: 0,
            opposite: true,
          },
          {
            seriesName: 'factor',
            labels: {
              formatter(val) {
                return `${(val).toFixed(0)}`
              },
            },
            title: {
              text: 'Factor',
            },
            minWidth: 0,
            opposite: true,
          },
          {
            seriesName: 'factor',
            show: false,
          },
        ],
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: true,
          y: {
            // eslint-disable-next-line no-unused-vars
            formatter(val, { seriesIndex }) {
              if (val) {
                if (seriesIndex < 2) {
                  return `${(+(val).toFixed(0)).toLocaleString('en-US')} GiB` // Used Capacity, Pre Dedup
                }
                return `${(+(val).toFixed(0)).toLocaleString('en-US')}` // Physical Used Factor, Logical Used Factor, Global Comp.
              }

              return null
            },
          },
          x: {
            formatter(val) {
              return moment.utc(val).format('L')
            },
          },
        },
      }
    },
    postDedupSeries() {
      return {
        name: 'Used capacity',
        type: 'column',
        data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.startTime)).toISOString(), x.postComp / 1024 / 1024 / 1024]),
      }
    },
    preDedupSeries() {
      return {
        name: 'Pre dedup',
        type: 'column',
        data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.startTime)).toISOString(), x.preComp / 1024 / 1024 / 1024]),
      }
    },
    globalCompPercentageSeries() {
      return {
        name: 'Global comp.',
        type: 'line',
        data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.startTime)).toISOString(), x.globalCompPercentage]),
      }
    },
    physicalUsedFactorSeries() {
      return {
        name: 'Physical used factor',
        type: 'line',
        data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.startTime)).toISOString(), x.physicalUsedFactor]),
      }
    },
    logicalUsedFactorSeries() {
      return {
        name: 'Logical used factor',
        type: 'line',
        data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.startTime)).toISOString(), x.logicalUsedFactor]),
      }
    },
  },
  watch: {
    timeRange() {
      this.loadStatistic()
    },
    mtreeName() {
      this.loadStatistic()
    },
  },
  mounted() {
    this.loadStatistic()
  },
  methods: {
    updateChart() {
      const opts = this.chartOptions

      const oldestStatistic = this.oldestStatistic?.startDate
      const xAxisMax = moment(this.endDate).endOf('day') ?? moment(oldestStatistic)

      // Update X axis with new time range
      opts.xaxis = this.$apexcharts.merge(opts.xaxis, {
        min: moment(this.startDate).startOf('day').valueOf(),
        max: xAxisMax.valueOf(),
      })

      this.$apexcharts.exec(this.chartOptions.chart.id, 'updateOptions', opts)
    },
    loadStatistic() {
      DataDomainApexService.getStatisticListByMtreeAsync(this.asupId, {
        from: moment(this.startDate).subtract(1, 'days').startOf('day').toISOString(),
        to: moment(this.endDate).add(1, 'days').endOf('day').toISOString(),
        mtree: this.mtreeName,
      }, {
        disableTenantFilter: true,
      })
        .then(result => {
          this.statistic = result.items
          this.chartDataLoaded = true
          this.updateChart()
        })
    },
    adjustByTimezone(dateTime) {
      const offset = this.$moment.tz.zone(this.timezone).utcOffset(dateTime)
      return dateTime.clone().subtract(offset, 'minutes')
    },
  },
}

</script>
