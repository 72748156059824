<template>
  <apexchart
    type="line"
    :options="chartOptions"
    :series="postDedupAvgSeries"
  />
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
import moment from '@/libs/moment'
import DataDomainApexService from '@/service/datadomain-apex.service'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    timeRange: {
      type: Object,
      default: () => ({
        startDate: moment().subtract(14, 'days').toDate(),
        endDate: new Date(),
      }),
    },
    timezone: {
      type: String,
      default: 'Etc/UTC',
    },
    mtreeName: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      chartDataLoaded: false,
      isRendered: false,
      statistic: [],
    }
  },
  computed: {
    hasMtree() {
      return !!this.mtreeName
    },
    asupId() {
      return this.$route.params.id
    },
    startDate() {
      if (this.$route.query.start) {
        return this.$route.query.start
      }

      return this.timeRange.startDate
    },
    endDate() {
      if (this.$route.query.end) {
        return this.$route.query.end
      }

      return this.timeRange.endDate
    },
    xAxisCategories() {
      return [...new Set(this.statistic.map(x => this.dateLabel(x)))] // unique
    },
    chartOptions() {
      return {
        chart: {
          id: 'postCompChart',
          type: 'bar',
          stacked: true,
          height: 350,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        stroke: {
          show: true,
          curve: 'straight',
          lineCap: 'butt',
          width: 1.5,
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
          background: {
            dropShadow: {
              enabled: true,
            },
          },
          offsetY: -10,
          formatter(val) {
            if (val) {
              return `${(+(val).toFixed(0)).toLocaleString('en-US')} GiB`
            }
            return val
          },
        },
        markers: {
          size: 0,
        },
        title: {
          text: this.mtreeName ? `${this.mtreeName} avg. used Capacity` : '',
          align: 'left',
        },
        yaxis: [
          {
            seriesName: 'Used Capacity',
            labels: {
              formatter(val) {
                if (val) {
                  return `${(val).toFixed(0)} GiB`
                }
                return val
              },
            },
            title: {
              text: 'Used Capacity',
            },
            minWidth: 0,
          },
        ],
        xaxis: {
          categories: this.xAxisCategories,
        },
        tooltip: {
          shared: true,
          inverseOrder: false,
          onDatasetHover: {
            highlightDataSeries: true,
          },
          y: {
            // eslint-disable-next-line no-unused-vars
            formatter(val, config) {
              if (val) {
                return `${(+(val).toFixed(0)).toLocaleString('en-US')} GiB`
              }

              return null
            },
          },
        },
      }
    },
    postDedupAvgSeries() {
      const seriesResult = this.statistic.reduce((p, c) => {
        let series = p.find(x => x.name === c.mtree)
        if (!series) {
          series = {
            name: c.mtree,
            type: 'bar',
            data: Array(this.xAxisCategories.length).fill(null),
          }
          p.push(series)
        }

        const dataIndex = this.xAxisCategories.indexOf(this.dateLabel(c))
        const avgPostComp = c.avgPostComp / 1024 / 1024 / 1024
        series.data[dataIndex] = avgPostComp
        return p
      }, [])

      if (!this.hasMtree) { // chart is filtered by one mtree. dont show sum line
      // add series with sum postcomp of all mtrees
        const sumSeries = {
          name: 'All Mtrees',
          type: 'line',
          data: Array(this.xAxisCategories.length).fill(0),
        }

        for (let i = 0; i < this.xAxisCategories.length; i += 1) {
          sumSeries.data[i] = seriesResult.reduce((p, c) => p + c.data[i], 0)
        }

        seriesResult.unshift(sumSeries) // push to first position
      }

      return seriesResult
    },
  },
  watch: {
    timeRange() {
      this.loadStatistic()
    },
    mtreeName() {
      this.loadStatistic()
    },
  },
  mounted() {
    this.loadStatistic()
  },
  methods: {
    loadStatistic() {
      DataDomainApexService.getStatisticAggListByMtreeAsync(this.asupId, {
        from: moment(this.startDate).subtract(1, 'days').startOf('day').toISOString(),
        to: moment(this.endDate).add(1, 'days').endOf('day').toISOString(),
        mtree: this.mtreeName,
      }, {
        disableTenantFilter: true,
      })
        .then(result => {
          this.statistic = result.items
          this.chartDataLoaded = true
        })
    },
    dateLabel(stat) {
      return `${stat.month}.${stat.year}`
    },
  },
}

</script>
