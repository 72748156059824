// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig, parseGetParams } from './serviceManager'

export default {
  async getAllListAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params

    if (!requestConfig.params.maxResultCount) {
      requestConfig.params.maxResultCount = 50
    }

    let page = 0
    let totalCount = 0

    const result = []

    do {
      requestConfig.params.skipCount = page * requestConfig.params.maxResultCount

      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get('/autosupport/datadomain-apex', requestConfig)
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },

  async getAsync(id, config) {
    const result = await axios.get(`/autosupport/datadomain-apex/${id}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async triggerBuildAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.post(`/autosupport/datadomain-apex/${id}/build`, {}, requestConfig)
    return result.data
  },

  async triggerBuildAllAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.post('/autosupport/datadomain-apex/build', {}, requestConfig)
    return result.data
  },

  async getStatisticListByMtreeAsync(apexId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/datadomain-apex/${apexId}/statistic`, requestConfig)
    return result.data
  },

  async getStatisticAggListByMtreeAsync(apexId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/datadomain-apex/${apexId}/statistic/agg`, requestConfig)
    return result.data
  },

  async getStatisticDataPerQuarter(apexId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/datadomain-apex/${apexId}/statistic/per-quarter`, requestConfig)
    return result.data
  },

  async downloadStatisticDataPerQuarter(apexId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams
    requestConfig.responseType = 'arraybuffer'

    const result = await axios.get(`/autosupport/datadomain-apex/${apexId}/statistic/per-quarter/download`, requestConfig)

    return result
  },

  async getStatisticDataPerMonth(apexId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/datadomain-apex/${apexId}/statistic/per-month`, requestConfig)
    return result.data
  },

  async downloadStatisticDataPerMonth(apexId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams
    requestConfig.responseType = 'arraybuffer'

    const result = await axios.get(`/autosupport/datadomain-apex/${apexId}/statistic/per-month/download`, requestConfig)

    return result
  },

  async getStatisticDailyAggList(apexId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/datadomain-apex/${apexId}/statistic/per-day`, requestConfig)
    return result.data
  },
}
