<template>
  <div>

    <b-card title="Avg. used capacity">
      <MtreePostCompAvgChart
        :time-range="{startDate: $moment().subtract(1, 'year').toDate(), endDate: new Date()}"
      />
    </b-card>

    <b-row>
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-card>
          <ul class="mtreeSelectionList">
            <li
              v-for="mtree in mtrees"
              :key="mtree.name"
            >
              <b-form-radio
                v-model="selectedMtree"
                :value="mtree.name"
                name="mtree-list"
              >
                {{ mtree.name }}
              </b-form-radio>
            </li>
          </ul>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="8"
      >
        <b-card>
          <b-form inline>
            <b-form-group
              label="Time range"
              label-sr-only
            >
              <date-range-picker
                v-model="timeRangeDetail"
                :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last90Days']"
                :max-selectable-days="2000"
              />
            </b-form-group>
          </b-form>
          <ApexDDChart
            v-if="selectedMtree"
            :time-range="timeRangeDetail"
            :mtree-name="selectedMtree"
          />
        </b-card>

        <b-card>
          <b-form inline>
            <b-form-group
              label="Time range"
              label-sr-only
            >
              <date-range-picker
                v-model="timeRangeAggPostComp"
                :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last90Days', 'last180Days', 'last365Days', 'last2Years', 'last3Years', 'last5Years']"
                :max-selectable-days="2000"
              />
            </b-form-group>
          </b-form>
          <MtreePostCompAvgChart
            v-if="selectedMtree"
            :time-range="timeRangeAggPostComp"
            :mtree-name="selectedMtree"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BForm, BFormGroup, BFormRadio,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import ApexDDChart from './charts/ApexChart.vue'
import MtreePostCompAvgChart from './charts/MtreePostCompAvgChart.vue'

export default {
  components: {
    BRow, BCol, BCard, BForm, BFormGroup, DateRangePicker, ApexDDChart, MtreePostCompAvgChart, BFormRadio,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timeRangeDetail: {
        range: 'last14Days',
      },
      timeRangeAggPostComp: {
        range: 'thisYear',
      },
      selectedMtree: null,
    }
  },
  computed: {
    mtrees() {
      if (this.asup.details && this.asup.details.config && this.asup.details.config.mtrees) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.asup.details.config.mtrees.sort((a, b) => (a.name > b.name ? 1 : -1))
      }

      return []
    },
  },
  watch: {
    selectedMtree() {
      this.updateRoute()
    },
  },
  beforeMount() {
    if (this.$route.query.mtree) {
      this.selectedMtree = this.$route.query.mtree
    }
  },
  methods: {
    updateRoute() {
      this.$router.replace({
        path: this.$route.path,
        query: {
          mtree: this.selectedMtree,
        },
      }).catch(() => {})
    },
  },
}
</script>

<style scoped>
  .config-table-wrapper {
    max-height: 400px;
    overflow-y: auto
  }
  .form-inline {
    place-items: flex-start;
    margin-bottom: 10px;
  }

  .form-group {
    margin-right: 15px;
  }

  .mtreeSelectionList {
    list-style-type: none;
    padding-left: 0;
  }
</style>
